/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    code: "code",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Signatu allow you to create Privacy Policies in multiple languages. The policies are based\non answers you give in a comprehensive questionnaire, and the generated clauses are\npeer reviewed by our legal network."), "\n", React.createElement(_components.p, null, "The Privacy Policies are versioned and given two URLs - a ", React.createElement(_components.code, null, "permaLink"), " that refers to the\nspecific version, and an ", React.createElement(_components.code, null, "activeLink"), " which refers to the currently active version of\nthe policy. The policies are published to our CDN."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/08a594bff5764b864f0bd59c1f221fdc/policy.svg",
    alt: "Policy Architecture"
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
